import React, { Fragment } from 'react';

import { Chevron } from 'components/Shared/Icons/Icons';
import { colors } from 'styles/cp';

export interface Props {
  opened: boolean;
  handler: Function;
  display?: string;
  float?: string;
  btnStyling?: Record<string, string | number>;
}

const ExtendBtn: React.FC<Props> = (props) => {
  const { opened = false, handler, display = 'inline', float = 'right', btnStyling = {} } = props;

  return (
    <Fragment>
      <div className="chev-w" onClick={() => handler()} aria-label="Extend button">
        <button className="chevron-btn" style={btnStyling}>
          <Chevron width={14} height={14} color={colors.gray03} />
        </button>
      </div>

      <style jsx>{`
        .chev-w {
          cursor: pointer;
          text-align: center;
          height: 18px;
          margin: auto;
          display: ${display};
          float: ${float};

          .chevron-btn {
            border: none;
            background-color: inherit;
          }

          :global(svg) {
            transition: all 0.15s linear;
            transform: rotate(${opened ? 0 : 180}deg);
          }
        }
      `}</style>
    </Fragment>
  );
};

export default ExtendBtn;
